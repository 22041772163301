import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <div className='fixed items-center top-0 z-50 flex justify-between w-full p-4 bg-white shadow h-[4rem]'>
        <img className='w-8' src="/uae.webp" alt="" />
        <img className='h-8' src="/logo.png" alt="" />
        <div className="flex items-center justify-center h-8 w-8 rounded-full bg-[rgba(242,245,248,.949)]">
<Link to='/cart'>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.225 8.725a.625.625 0 0 0 0 .884l.333.333c1.901 1.901 4.983 1.901 6.884 0l.333-.333a.625.625 0 1 0-.884-.884l-.333.333a3.618 3.618 0 0 1-5.116 0l-.333-.333a.625.625 0 0 0-.884 0z" fill="#B800C4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1.042a3.959 3.959 0 0 0-3.93 3.483c-.328.062-.63.153-.92.286A4.792 4.792 0 0 0 3.107 6.59c-.3.47-.478.996-.618 1.638-.137.629-.246 1.413-.385 2.41l-.007.056c-.178 1.283-.318 2.286-.36 3.094-.043.82.009 1.505.256 2.132a4.792 4.792 0 0 0 2.109 2.423c.588.33 1.258.476 2.076.546.807.07 1.82.07 3.115.07h1.414c1.295 0 2.308 0 3.115-.07.818-.07 1.488-.215 2.076-.546a4.792 4.792 0 0 0 2.11-2.423c.246-.627.298-1.311.255-2.132-.042-.808-.182-1.811-.36-3.095l-.008-.055c-.138-.997-.247-1.781-.384-2.41-.14-.642-.318-1.168-.618-1.638a4.791 4.791 0 0 0-2.043-1.779 3.97 3.97 0 0 0-.92-.286A3.959 3.959 0 0 0 10 1.042zm-.714 3.333c-.752 0-1.385 0-1.927.021a2.71 2.71 0 0 1 5.282 0c-.542-.02-1.175-.02-1.927-.02H9.287zM6.732 5.691c.615-.065 1.412-.066 2.591-.066h1.354c1.18 0 1.976.001 2.591.066.46.048.784.13 1.061.256.62.284 1.144.74 1.51 1.315.19.297.327.663.45 1.232.126.577.23 1.313.372 2.342.183 1.32.315 2.27.354 3.018.039.742-.018 1.22-.171 1.609a3.542 3.542 0 0 1-1.559 1.79c-.364.205-.83.327-1.57.391-.747.064-1.706.065-3.038.065H9.323c-1.332 0-2.291 0-3.038-.065-.74-.064-1.206-.186-1.57-.39a3.542 3.542 0 0 1-1.559-1.791c-.153-.388-.21-.867-.17-1.61.038-.748.17-1.698.353-3.017.143-1.029.246-1.765.371-2.342.124-.569.261-.935.45-1.232.367-.574.891-1.03 1.51-1.314.278-.128.602-.209 1.062-.257z" fill="#B800C4"/>
</svg>
</Link>
        </div>

    </div>
  )
}

export default Header