import Cards from "./Components/Cards";
import Header from "./Components/Header";
import CountdownTimer from "./CountDown";

function App() {
  const initialTimeInSeconds = 3 * 3600 + 12 * 60 + 47
  return (
    <div className="flex flex-col w-full min-h-screen bg-white pt-[4rem]">
      <div className="relative h-[166px] flex items-center w-full ">
      <img src="/bg.png" className="object-contain w-full h-fit" alt="" />
      <div className="absolute flex flex-col items-end mt-4 right-4">
        <span className="text-[#1D481F] mb-2">Get <span className="text-2xl font-bold text-white">-50%</span> on all gifts!</span>
        <CountdownTimer initialTime={initialTimeInSeconds} />
      </div>
      </div>
      <Cards />
    </div>
  );
}

export default App;
