import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import jsonData from '../../updated_products.json'; // Adjust the path as needed
import { useCart } from 'react-use-cart';
import { useStoreActions, useStoreState } from 'easy-peasy';

const Card = () => {
  const items = useStoreState(state => state.cart.items);
  const addItem = useStoreActions(actions => actions.cart.addItem);

  
  const navigate = useNavigate();
    const {slug} = useParams()
    const [cardData, setCardData] = useState(        {
        "node": {
          "tag": {
            "name": ""
          },
          "brand": {
            "name": "",
            "nameEn": "",
            "slug": "",
            "brandImageData": "",
            "denominationRange": "",
            "redemptionType": "",
            "isOffer": false
          }
        }
      }); // State to hold the found card data



      useEffect(()=>{
        const products = jsonData.data.brandsByCategory.edges;
    
        // Find the card by slug
        const foundCard = products.find((product) => product.node.brand.slug === slug);
    
        // Update the state with found card or null if not found
        setCardData(foundCard ? foundCard : null);
      },[])
    
      // Handle the form submission
      const [amount, setAmount] = useState(50); // Default amount
      const [customAmount, setCustomAmount] = useState(null); // Custom amount input state
      const [isCustom, setIsCustom] = useState(false); // Check if "CUSTOM" is selected
      const amounts = [10, 50, 100, 200, 500, 1000]; // Predefined amounts
    
      const increment = () => setAmount((prevAmount) => prevAmount + 10);
      const decrement = () => setAmount((prevAmount) => (prevAmount > 10 ? prevAmount - 10 : prevAmount));
      
      const handleCustomChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value)) {
          setCustomAmount(value);
          setAmount(value);
        } else {
          setCustomAmount(''); // Reset if the input is invalid
        }
      };
    
      const handlePresetAmount = (amt) => {
        setIsCustom(false); // Disable custom when a preset is selected
        setCustomAmount(null); // Clear custom input
        setAmount(amt); // Set the amount
      };
    


      const [quantity2, setQuantity2] = useState(1); // Default quantity
      const [selectedOption2, setSelectedOption2] = useState('self'); // Track selected option
      
      const [staticquant, setstaticquant] = useState(null)
      useEffect(()=>{setstaticquant(quantity2)},quantity2)
      
      const increment2 = () => setQuantity2((prevQuantity) => prevQuantity + 1);
      const decrement2 = () => setQuantity2((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
      const selectOption2 = (option) => setSelectedOption2(option);

      const handleAddToCart = () => {
        const itemInCart = items.find(item => item.id === cardData.node.brand.slug);

        console.log("Item in cart before update:", itemInCart);
        console.log("Quantity to add/update:", quantity2);
        console.log("Cart items before add/update:", items);

          // Otherwise, add the item to the cart with the selected quantity
          addItem({
            id: cardData.node.brand.slug,
            name: cardData.node.brand.name,
            price: amount,
            quantity: quantity2, // Use the updated quantity
            img: cardData.node.brand.brandImageData,
          });
        
        
        console.log("Cart items after add/update:", items);

    navigate('/cart'); // Redirect to the cart page after adding/updating the item
    


      };
      return (
    <div className='flex flex-col pb-52 items-center p-6 bg-[#f2f5f8] w-full pt-20'>
        <div className="flex items-center justify-between w-full">
            <Link className='w-20' to='/'>
            <svg className="fill-[#b408a4] w-4" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowBackIosIcon"><path d="M11.67 3.87 9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"></path></svg>
            </Link>
            <span className='text-xl text-[#3a1a5f] font-medium text-center'>{cardData.node.brand.name}</span>
            <div className="w-20"></div>
        </div>
        <div className="relative flex justify-center w-full mt-6">
        <img className='w-72 rounded-2xl' src={`/assets/img${cardData.node.brand.brandImageData}`} alt="" />
            <div className="absolute rounded border border-white -bottom-3 flex items-center py-1 justify-center px-4 bg-[#f2f5f8]">
                <span className='text-[#00cca8] font-medium text-[10px] leading-none'>ONLINE</span>
            </div>
        </div>
        <span className='text-sm text-[#545454] mt-6'>Validity 12 months | {cardData.node.brand.denominationRange}</span>
        <div className="flex flex-col items-center w-full space-y-4">

      {/* Increment/Decrement buttons */}
      <div className="flex items-center my-6 space-x-4">
        <button
          className={`w-6 h-6 flex items-center justify-center  ${isCustom ? 'opacity-50' : 'bg-white'} rounded-full `}
          onClick={decrement}
          disabled={isCustom} // Disable when custom input is active
        >
          <img className='w-6 ' src="/minus-circle.png" alt="" />
        </button>

        {/* Display Amount or Custom Input */}
        {isCustom ? (
          <input
            type="number"
            value={customAmount || ''}
            onChange={handleCustomChange}
            className="w-24 text-center text-xl font-semibold text-[#3a1a5f] border-b-2 border-[#b408a4] focus:outline-none"
            placeholder="AED"
          />
        ) : (
          <div className="flex justify-center w-44 text-2xl font-semibold  text-[#3a1a5f]">
            AED {amount}
          </div>
        )}

        <button
          className={`w-6 h-6 flex items-center justify-center text-[#b408a4] ${isCustom ? 'opacity-50' : 'bg-white'} rounded-full `}
          onClick={increment}
          disabled={isCustom} // Disable when custom input is active
        >
          <img className='w-6 ' src="/plus-circle.png" alt="" />
        </button>
      </div>

      {/* Predefined amount options */}
      <div className="flex items-center w-full space-x-4 overflow-x-scroll">
        {/* <button
          className={` flex-shrink-0 text-xs text-[#b408a4] bg-white w-[85px] h-[50px] border rounded-lg ${isCustom ? 'border-[#b408a4]' : 'border-transparent'}`}
          onClick={() => setIsCustom(true)}
        >
          CUSTOM
        </button> */}


        {amounts.map((amt) => (
            <button
            key={amt}
            className={` flex-shrink-0 flex flex-col items-center justify-center bg-white w-[85px] h-[50px] border-2 rounded-lg ${amount === amt && !isCustom ? 'border-[#b408a4] text-[#b408a4]' : 'border-transparent'}`}
            onClick={() => handlePresetAmount(amt)}
            >
            <span className='text-xs'>AED</span>
            <span className='font-semibold'>{amt}</span>
          </button>
        ))}

      </div>
    </div>
        <div className="grid w-full grid-cols-2 gap-4">
          <span  className='col-span-2  font-semibold text-[#3a1a5f]  mt-12'>How to use</span>
          <img src="/3bc4f11b-85f7-47f0-9082-0bf6de7759ee.png" alt="" />
          <img src="/27fc4f3e-48f8-4f93-99a1-80f5cc8286af.png" alt="" />
          <img src="/6c39de4b-3519-4612-a94c-debcb95ea2ec.png" alt="" />
        </div>

        <div className="fixed bottom-0 flex flex-col items-center w-full p-4 bg-white">
      {/* Quantity Section */}
      <div className="flex items-center justify-between w-full">
        <p className="text-gray-600">Quantity</p>
        <div className="flex items-center space-x-4">
          
        <button
          className="flex items-center justify-center w-8 h-8 text-[#b800c4] text-xl font-semibold border-[#b800c4] rounded-full"
          onClick={decrement2}
        >
          –
        </button>
        <span className="text-lg font-semibold">{quantity2}</span>
        <button
          className="flex items-center justify-center w-8 h-8 text-[#b800c4] text-xl font-semibold border-[#b800c4] rounded-full"
          onClick={increment2}
        >
          +
        </button>
          </div>
      </div>
        <div className="w-full mt-6 mb-8 border-b"></div>
      {/* Buy Option Section */}
      <div className="flex items-center w-full space-x-4">
        <button
          className={`px-4 w-full py-4  rounded-lg font-semibold border border-[#b800c4] text-[#b800c4]`}
          onClick={handleAddToCart}
        >
          Buy For Self
        </button>
        <button
          className={`px-4 w-full  py-4 rounded-lg font-semibold text-white bg-[#b800c4]`}
          onClick={handleAddToCart}
        >
          Gift a Friend
        </button>
      </div>
    </div>
    </div>
  )
}

export default Card