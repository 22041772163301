import React, {useContext} from "react"
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
  FaMinus,
  FaPlus,
  FaTrash,
  FaRegCreditCard,
} from "react-icons/fa"

const Loading = ({setActiveComponent}) => {

  return (
    <div className="flex flex-col items-center justify-center w-full h-full min-h-52">

<img className="w-12" src="/loader.gif" alt="" />

      {/* <span className="mt-6 loader"></span> */}
    </div>
  )
}

export default Loading
