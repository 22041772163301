import React, { useEffect, useRef, useState } from 'react'

import cards from '../../updated_products.json'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component';
const Cards = () => {
    const products = cards.data.brandsByCategory.edges


    const productsPerPage = 10;
    const [visibleProducts, setVisibleProducts] = useState(products.slice(0, productsPerPage)); // Initially load 10 products
    const [hasMore, setHasMore] = useState(true);
  
    // Function to load more products
    const loadMoreProducts = () => {
      const startIndex = visibleProducts.length;
      const newProducts = products.slice(startIndex, startIndex + productsPerPage);
  
      if (newProducts.length > 0) {
        setVisibleProducts((prevProducts) => [...prevProducts, ...newProducts]);
      } else {
        setHasMore(false); // No more products to load
      }
    };
  return (
    <div className='flex flex-col gap-4 p-4'>
        <div className="col-span-2">
            <span className='text-xl font-medium text-[#3a1a5f]'>Single Brands | 231 eGift Cards</span>
        </div>
        <InfiniteScroll
      dataLength={visibleProducts.length} // Length of the current visible products
      next={loadMoreProducts} // Function to load more products
      hasMore={hasMore} // Whether there are more products to load
      loader={<div className='loader'></div>} // Loading indicator
      endMessage={<p>No more products to load</p>} // Message displayed when all products are loaded
    >
    <div className="grid grid-cols-2 gap-4"> 
      {visibleProducts.map((product) => (
        <Link to={`/card/${product.node.brand.slug}`} className="flex flex-col mb-8" key={product.node.brand.slug}>
          <img className="rounded-xl" src={`/assets/img${product.node.brand.brandImageData}`} alt={product.node.brand.name} />
          <span className="my-3 text-sm font-medium text-[#3a1a5f]">{product.node.brand.name}</span>
          <span className="text-sm text-[#545454]">{product.node.brand.denominationRange}</span>
        </Link>
      ))}
    </div>
    </InfiniteScroll>
        <div id="scroll-observer" className="h-1"></div>
        {visibleProducts.length < products.length && <div className="loader"></div>}
    </div>
  )
}

export default Cards