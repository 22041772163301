import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Card from './Components/Card';
import Header from './Components/Header';
import Cart from './Components/Cart';
import { CartProvider } from 'react-use-cart';
import { StoreProvider } from 'easy-peasy';
import store from './store'; // Import the store
import Checkout from './Components/Checkout';
import { PromoProvider } from "./providers/Init"



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
    <StoreProvider store={store}>
      <PromoProvider>

      <Header />
      <Routes>
        <Route path='/' element={<App />} />
        <Route path='/card/:slug' element={<Card />} />
        <Route path='/cart' element={<Cart />} />
        <Route path='/checkout/:ammount' element={<Checkout />} />
      </Routes>
      </PromoProvider>
    </StoreProvider>

    </Router>
  </React.StrictMode>
);


