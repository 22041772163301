import { action, computed } from 'easy-peasy';

const cartModel = {
  items: [],

  // Add item to the cart
  addItem: action((state, payload) => {
    const existingItem = state.items.find(item => item.id === payload.id);
    if (existingItem) {
      existingItem.quantity += payload.quantity;
    } else {
      state.items.push({ ...payload, quantity: payload.quantity });
    }
  }),

  // Remove item from the cart
  removeItem: action((state, payload) => {
    state.items = state.items.filter(item => item.id !== payload);
  }),

  // Update quantity of an item in the cart
  updateItemQuantity: action((state, payload) => {
    const item = state.items.find(item => item.id === payload.id);
    if (item) {
      item.quantity = payload.quantity;
    }
  }),

  // Computed property to calculate total price of items in the cart
  cartTotal: computed((state) => {
    return state.items.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
  }),
};

export default cartModel;
